<template>
  <div class="mybill">
    <div class="mybill-num">发票 共{{ length }}张</div>
    <div class="mybill-user" v-for="(item, index) in mybillLest" :key="index">
      <div class="mybill-name">
        <span>购方名称：{{ item.buyername }}</span>
        <span
          @click="billdetail(item.qrcodepath, item.downloadurl)"
          style="color: red"
        >
          发票详情
          <van-icon name="share" style="color: red" />
        </span>
      </div>
      <div class="mybill-news">
        <p>发票号：{{ item.invoiceno }}</p>
        <p>开票金额：{{ item.amount }}</p>
        <p>开票时间：{{ item.invoicedate }}</p>
      </div>
    </div>

    <div v-if="loading">
      <van-empty image="search" description="正在加载" />
    </div>
    <div v-if="isdata">
      <van-empty description="暂无数据" />
    </div>

    <el-dialog title="预览发票" :visible.sync="dialogTableVisibleBill">
      <div>
        <!-- <img
          src="http://121.37.203.141:8001/selectimage?invoiceCode=033001900111&invoiceNo=33023065&imageType=jpg"
        /> -->
        <img :src="ppath" />
      </div>
      <a :href="dpath"> <van-button type="primary">下载</van-button></a>
    </el-dialog>
  </div>
</template>
<script>
import Event from '../util/event.js'
export default {
  data() {
    return {
      length: 0,
      mybillLest: [],
      imagePreviewDialog: '',
      mobile: '',
      ppath: '', // 图片地址
      dpath: '', //下载地址
      dialogTableVisibleBill: false,
      loading: true,
      isdata: false
    }
  },
  mounted() {
    let that = this
    this.mobile = localStorage.getItem('mobile')

    this.$axios.get(`list?mobile=${this.mobile}`).then((res) => {
      if (res.data.retcode == '0000') {
        Event.$emit('creatMyBill')
        this.length = res.data.length
        this.mybillLest = res.data.data
        this.loading = false
        if (this.length <= 0) {
          that.isdata = true
        }
      } else {
        this.$dialog
          .alert({
            message: res.data.retmessage
          })
          .then(() => {
            // on close
          })
      }
    })
  },
  methods: {
    backHomepage() {
      this.$router.push('/index')
    },
    billdetail(qrcodepath, downloadurl) {
      this.ppath = qrcodepath
      this.dpath = downloadurl
      this.dialogTableVisibleBill = true
    }
  }
}
</script>
<style>
.mybill .mybill-user {
  margin: 15px;
}
.mybill .mybill-name {
  font-size: 16px;
  font-weight: 700;
  background: #75cbe4;
  line-height: 40px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
}
.mybill .mybill-name .dowm {
  margin-right: 15px;
}
.mybill .mybill-name .van-icon {
  font-size: 18px;
}
.mybill .mybill-news {
  background: #eee;
  padding: 10px 15px;
}
.mybill .mybill-news {
  line-height: 30px;
}
.mybill .mybill-num {
  margin: 65px 0 0 15px;
}
.mybill .el-dialog {
  width: 100%;
  height: auto;
  text-align: center;
}
.mybill .el-dialog img {
  width: 100%;
  border: 1px solid #ddd;
  box-shadow: #eee 0 0 5px 5px;
}
.mybill .el-dialog__body {
  text-align: center;
}
.mybill .el-dialog .van-button--normal {
  width: 80%;
  margin-top: 50px;
  border-radius: 5px;
}
.mybill .mybill-name .el-button {
  padding: 0;
}
</style>